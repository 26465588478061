"use client";
import React, { useRef, useState } from "react";
import styles from "./OurGames.module.css";
import { IPopularGamesUS } from "../../../lib/types/home.us";
import Slider, { Settings } from "react-slick";
import CustomCarousel from "../../common/CustomCarouselNaviagtion/CustomCarouselNavigation";
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import { NextArrow, PrevArrow } from "../ArrowComponent/ArrowComponent";
import { multipleClassName } from "../../../lib/utils/helpers/helper";
import CustomLinkComponent from "../../common/CusotmLink/CustomLinkComponent";

const OurGames = (props: IPopularGamesUS) => {
  const gameData = props;
  const [nextSlide, setNextSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const sliderSettings: Settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    centerPadding: "0px",
    dots: false,
    arrows: true,
    autoplay: false,
    nextArrow: (
      <NextArrow onClick showInMobile={false} dark={false} active={true} />
    ),
    prevArrow: (
      <PrevArrow onClick showInMobile={false} dark={false} active={true} />
    ),
    beforeChange: (currentSlide, nextSlide) => {
      setNextSlide(nextSlide);
    },
    afterChange: (currentSlide) => {
      setCurrentSlide(currentSlide);
    },
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
        },
      },
    ],
  };

  return gameData.games ? (
    <div className={styles.parentContainer}>
      <div className={styles.bgImages} />
      <div className="container" style={{ position: "relative" }}>
        <div className={styles.waterMarkAndTextContainer}>
          <div className={styles.waterMarkNoWrap}>{gameData.watermark}</div>
          <p className={styles.subHeading}>{gameData.subHeading}</p>
          <h2 className={styles.heading}>{gameData.heading}</h2>
        </div>

        <div className={styles.sliderContainer}>
          <div className={styles.mainContainer}>
            <div className={styles.bgContainer} />
          </div>
          <Slider {...sliderSettings} ref={sliderRef}>
            {gameData.games.map((item, index) => {
              const isSecondBig =
                nextSlide === 0
                  ? index === 1 || index === gameData.games.length - 1
                  : Math.abs(index - nextSlide) === 1;
              return index === nextSlide ? (
                <div>
                  <div
                    className={styles.gameContainer}
                    key={"our_games_germany" + index}
                  >
                    <div
                      className={multipleClassName(
                        styles.gameImageContainer,
                        styles.centerImageContainer
                      )}
                    >
                      <CustomImageComponent
                        src={item.src}
                        alt="how to install"
                        objectFit={"contain"}
                        objectPosition={"center center"}
                        layout="fill"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className={styles.gameContainer}
                    key={"our_games_germany" + index}
                  >
                    <div
                      className={multipleClassName(
                        styles.gameImageContainer,
                        isSecondBig ? styles.secondImageContainer : ""
                      )}
                    >
                      <CustomImageComponent
                        src={item.src}
                        alt="how to install"
                        objectFit={"contain"}
                        objectPosition={"center center"}
                        layout="fill"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div>
          <CustomLinkComponent
            href={gameData.games[nextSlide].url}
            mobStyle={{ textDecoration: "none", color: "white" }}
            desktopStyle={{ textDecoration: "none", color: "white" }}
          >
            <div className={styles.gameDescContainer}>
              <p className={styles.gameName}>
                {gameData.games[nextSlide].name}
              </p>
              <p className={styles.gameDesc}>
                {gameData.games[nextSlide].about}
              </p>
            </div>
          </CustomLinkComponent>
          <CustomCarousel
            nextSlide={nextSlide}
            currentSlide={currentSlide}
            slideCount={gameData.games.length}
            sliderRef={sliderRef}
            hideArrow={true}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default OurGames;
